import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper/bundle"

// Connects to data-controller="swiper"
export default class extends Controller {

  connect() {
    let maxElem = Math.floor(this.element.getElementsByClassName("swiper-slide").length / 2);
    this.swiper = new Swiper(".swiper-carousel", {
      lazy: true,
      slidesPerView: 1,
      spaceBetween: 45,
      loop: maxElem > 1,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true
      },
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next"
      },
      autoplay: {
        delay: 1500,
      },
      breakpoints: { 
        // 576: {
        //   spaceBetween: 50,
        //   slidesPerView: maxElem > 1 ? 2 : 1
        // }
        768: {
          spaceBetween: 20,
          slidesPerView: 2
        }
      }

    })
  }
}
