import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["add_item", "template"]

  add_association(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    if (item.getAttribute("data-new-record") === "true") {
      item.remove()
    } else {

      [...item.getElementsByClassName("form-control")].forEach(element => {
        element.setAttribute("disabled", "disabled")
      });

      let button = undefined;
      if (event.target.tagName == "I") {
        button = event.target.parentNode.parentNode;
      } else if (event.target.tagName == "Span") {
        button = event.target.parentNode;
      } else {
        button = event.target;
      }

      button.classList.add("d-none");
      button.nextElementSibling.classList.remove("d-none");
    }
  }

  undo_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = null;

    [...item.getElementsByClassName("form-control")].forEach(element => {
      element.removeAttribute("disabled");
    });

    let button = undefined;
    if (event.target.tagName == "I") {
      button = event.target.parentNode.parentNode;
    } else if (event.target.tagName == "Span") {
      button = event.target.parentNode;
    } else {
      button = event.target;
    }

    button.classList.add("d-none");
    button.previousElementSibling.classList.remove("d-none");
  }
}