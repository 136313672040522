import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="upload-progress"
export default class extends Controller {
  static targets = ["progress", "progressText", "progressWidth"]

  connect() {
    this.element.addEventListener("direct-upload:progress", this.updateProgress.bind(this))

    this.element.addEventListener("direct-upload:error", event => {
      event.preventDefault()
      const {id, error} = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.add("direct-upload--error")
      element.setAttribute("title", error)
      const progElement = document.getElementById(`direct-upload-progress-${id}`)
      progElement.classList.remove("progress-bar-animated")
      progElement.classList.remove("bg-success")
      progElement.classList.add("bg-danger")
      progElement.textContent = `Upload failed: ${error}`
    })

    this.element.addEventListener("direct-upload:initialize", event => {
      const { target, detail } = event
      const { id, file } = detail
      target.insertAdjacentHTML("beforebegin", `
        <div id="direct-upload-${id}" class="progress direct-upload my-2">
          <div id="direct-upload-progress-${id}" class="direct-upload-progress progress-bar progress-bar-striped progress-bar-animated bg-success" style="width: 0%"></div>
          <span class="direct-upload-barlabel">${file.name}</span>
        </div>
      `)
    })
  }

  initializeUpload() {
    // direct-upload
    // clean up prev failed upload attempt
    const failedBar = document.querySelectorAll(".direct-upload--error")
    if (failedBar.length > 0) {
      failedBar.forEach(el => el.remove());    
    } 
  }

  updateProgress() {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${Math.round(progress)}%`
    progressElement.textContent = `${Math.round(progress)}%`
  }

  disconnect() {
    this.element.removeEventListener("direct-upload:progress", this.updateProgress)
  }
}
