import { Controller } from "@hotwired/stimulus"
import Filterizr from 'filterizr'

// Connects to data-controller="filterizr"
export default class extends Controller {
  connect() {
    const options = {
      animationDuration: 0.3,
      filterOutCss: {
        opacity: 0,
        transform: 'scale(0.25)'
      },
    }
    this.filterizr = new Filterizr(".filter-container", options)
  }

  runFilter(e) {
    for (const li of document.querySelectorAll("#product-flters > li.active")) {
      li.classList.remove("active");
    }

    e.currentTarget.classList.add("active");
  }
}
