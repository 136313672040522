import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="copy-button"
export default class extends Controller {
  static targets = ["popover"]
  static values = {
    data: String,
  }

  connect() {
    this.popover = new bootstrap.Popover(this.popoverTarget, {
    content: "Copied!",
      trigger: "manual",
      placement: 'top'
    });

  }
  copyToClipboard(e) {
    navigator.clipboard.writeText(this.dataValue);
    setTimeout(this.togglePopover, 100, this.popover)
    setTimeout(this.togglePopover, 750, this.popover)
  }
  
  togglePopover(elem) {
    elem.toggle();
  }
}
