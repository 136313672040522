import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-auto-submit"
export default class extends Controller {
  static targets = [ "field" ]
// 
  connect() {
    // console.log()
    this.fieldTargets.forEach(element => {
      element.addEventListener("change", this.sendValue)
    });
  }

  sendValue() {
    this.form.requestSubmit();
  }
}