import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper/bundle"

// Connects to data-controller="swiper-sm-thumb"
export default class extends Controller {
  connect() {
    let nElem = this.element.getElementsByClassName("swiper-slide").length/2;
    this.swiperSm = new Swiper(".swiper-thumb-sm", {
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 10,
      centeredSlides: true,
      // loop: true,
      // slideToClickedSlide: true,

    })
    // console.log(nElem)
    this.swiperMain = new Swiper(".swiper-thumb-main", {
      lazy: true,
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 10,
      loop: true,
      // loopedSlides: nElem,
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next"
      },
      autoplay: {
        delay: 1500,
      },
      thumbs: {
        swiper: this.swiperSm,
      },
      // onSlideChangeStart: function(){
      //       this.swiperSm.swipeTo(this.swiperMain.activeIndex)
      // }
    })
    // this.swiperMain.controller.control = this.swiperSm;
    // this.swiperSm.controller.control = this.swiperMain;

  }
}
