import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="gallery-lightbox"
export default class extends Controller {
  static targets = [ "modal", "carousel", "carouselSlide"]
  connect() {
    // this.modal = new bootstrap.Modal(this.modalTarget);
    this.modalTarget.addEventListener('hidden.bs.modal', event => {
      Array.from(this.modalTarget.getElementsByTagName("video")).forEach(element => {
        element.pause();
      }); 
    });
    this.carouselTarget.addEventListener('slide.bs.carousel', event => {
      Array.from(this.carouselSlideTargets[event.from].getElementsByTagName("video")).forEach(element => {
        element.pause();
      });
    });

  }
}
