import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="jump-button"
export default class extends Controller {
  connect() {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
          e.preventDefault();

          document.querySelector(this.getAttribute('href')).scrollIntoView({
              behavior: 'smooth'
          });
      });
    });
  }
}
