import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="tomselect"
export default class extends Controller {
  static values = {
    url: String,
    name: String,
    label: String,
    value: String,
    search: Array,
    extraAttrs: Array,
    create: Boolean
  }

  connect() {
    this.tempValue = undefined
    this.initSelect()
  }
  
  dismiss() {
    if (this.element) {
      this.select.disable()
      this.tempValue = this.select.getValue()
      this.select.clear()
    }
  }
  
  reopen() {
    if (this.element) {
      this.select.setValue(this.tempValue)
      this.select.enable()
    }
  }

  initSelect() {
    if (this.element) {
      let additionalConfig = {}
      additionalConfig = this.getAdditionalConfig()
      // if (this.urlValue) {
      // }
      this.select = new TomSelect(
        this.element, {
          plugins: ['remove_button', "clear_button"],
          selectOnTab: true,
          placeholder: `Pilih ${this.nameValue}`,
          preload: "focus",
          ...additionalConfig
        })
      }
      // console.log(this.select, this.createValue)
    }
    
    getAdditionalConfig() {
      let global_url = this.urlValue;
      let element = this.element;
      return {
        // valueField: this.valueValue,
        // labelField: this.labelValue,
        // extraAttrs: this.extraAttrsValue,
        // searchField: this.searchValue,
        create: this.createValue,
        createFilter: function(input) { return input.length >= 4; }
        // load: function (query, callback) {
        //   var self = this;
        //   if (self.loading > 1) {
        //     callback();
        //     return;
        //   }
        //   if (global_url) {
        //     fetch(global_url)
        //     .then(response => response.json())
        //     .then(json => {
        //       let ids = Object.values(self.options).map(item => item["id"]);
        //       ids.forEach(id => {
        //         json.find((o) => {
        //           if (o.id == id) {
        //             self.options[id] = o;
        //           }
        //         })
        //       })
        //       callback(json);
        //       self.settings.load = null;
        //     }).catch(() => {
        //       callback();
        //     });
        //   }
        // },
        // render: {
        //   option: function(data, escape) {
        //     let content = element.nextElementSibling.nextElementSibling.innerHTML;
            
        //     const regxp = /\$\{([A-Z\_]+)\}/g;
        //     let keys = [...content.matchAll(regxp)]
        //     keys.forEach(key => {
        //       let repl = data[key[1].toLowerCase()] ?? ""
        //       content = content.replace(key[0], escape(repl))
        //     })
        //     return content;
        //   }
        // },
        // onItemAdd: function (val, item) {
        //   var data = this.options[val]; // <-- This is how to pull data-data from the original options
        //   let attrList = this.settings.extraAttrs;
        //   attrList.forEach(attr => {
        //     if (attr in data) {
        //       element.dataset[attr] = data[attr]; // stores the data-data as a json object
        //     }
        //   });
        // }

      }
    }
    
  }
  