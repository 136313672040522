// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import "swiper"
import "trix"
import "@rails/actiontext"
import "./richtext"
import Masonry from "masonry-layout"
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
import "./channels"
