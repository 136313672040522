import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// data-controller: modal
export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element)
  }

  open() {
    if (!this.modal.isOpened) {
      let forms = this.modal._element.getElementsByTagName("form")
      for (const form of forms) {
        if (form.method == "patch") {
          form.dataset.turboFrame = "modal"
        }
      }
      this.modal.show()
    }
  }

  close(event) {
    if (event.detail.success) {
      this.modal.hide()
    }
  }
}
